<template lang="pug">
  .inventory-group-table-wrapper
    table.inventory-group-table
      thead
        th.sticky-col.name.sortable(@click="handleSorting('name')")
          span {{ $t("inventory_group_matching.attributes.name") }}
          FaIcon.icon(:icon="sortingIcon('name')")
        th(
          v-for="ota in otas"
          :key="ota.id"
        ) {{ ota.name }}
        th.edit
        th.delete
      tbody
        InventoryGroupItem(
          v-for="inventoryGroup in inventoryGroups"
          :key="inventoryGroup.id"
          :inventory-group="inventoryGroup"
          :otas="otas"
          :sources-shops-by-otas="sourcesShopsByInventoryGroups[inventoryGroup.id]"
          @edit="$emit('edit', inventoryGroup)"
          @delete="$emit('delete', inventoryGroup)"
        )
</template>

<script>
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      InventoryGroupItem: () => import("./InventoryGroupItem")
    },

    mixins: [withSorting],

    props: {
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      sortingData: Object,
      sourcesShopsByInventoryGroups: Object
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/matchings.sass"

  .inventory-group-table-wrapper
    +listing-container(-43px)

    table
      +custom-table

      thead
        th
          +sticky-col
          top: 0
        .name
          width: 250px
</style>
